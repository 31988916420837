var exports = {};

exports = function isPreviewSupported(fileType) {
  if (!fileType) return false;
  var fileTypeSpecific = fileType.split("/")[1]; // list of images that browsers can preview

  if (/^(jpe?g|gif|png|svg|svg\+xml|bmp|webp|avif)$/.test(fileTypeSpecific)) {
    return true;
  }

  return false;
};

export default exports;